/*
Theme Name: fastr
Theme URI: http://themes.kanishkkunal.in/fastr
Author: Kanishk Kunal
Author URI: http://kanishkkunal.in
Description: Clean and minimalistic content-oriented responsive theme for faster sites suitable for your personal blog. With its modern look and attention to crisp typography, Fastr lets your readers focus on the content. Fastr uses absolutely no javascript and has a very minimal CSS for its layout and responsiveness.
Version: 1.2.0.0
License: GNU General Public License
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: fastr
Domain Path: /languages/
Tags: black, gray, white, one-column, fluid-layout, rtl-language-support, responsive-layout

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

fastr is based on Underscores http://underscores.me/, (C) 2012-2013 Automattic, Inc.

Resetting and rebuilding styles have been helped along thanks to the fine work of
Eric Meyer http://meyerweb.com/eric/tools/css/reset/index.html
along with Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
and Blueprint http://www.blueprintcss.org/
*/


/* =Reset
-------------------------------------------------------------- */

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
 h1, h2, h3, h4, h5, h6,
 p, blockquote, pre,
 ul, ol, dl, figure,
 %vertical-rhythm {
   margin-bottom: $spacing-unit / 2;
 }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	border: 0;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	font-weight: inherit;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
    
    
}
html {
	font-size: 62.5%; /* Corrects text resizing oddly in IE6/7 when body font-size is set using em units http://clagnut.com/blog/348/#c790 */
	overflow-y: scroll; /* Keeps page centered in all browsers regardless of content height */
	-webkit-text-size-adjust: 100%; /* Prevents iOS text size adjust after orientation change, without disabling user zoom */
	-ms-text-size-adjust:     100%; /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
	display: block;
}
ol, ul {
	list-style: none;
}
table { /* tables still need 'cellspacing="0"' in the markup */
	border-collapse: separate;
	border-spacing: 0;
}
caption, th, td {
	font-weight: normal;
	text-align: left;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
blockquote, q {
	quotes: "" "";
}
a:focus {
	outline: thin dotted;
}
a:hover,
a:active { /* Improves readability when focused and also mouse hovered in all browsers people.opera.com/patrickl/experiments/keyboard/test */
	outline: 0;
}
a img {
	border: 0;
}


/* =Global
----------------------------------------------- */

body,
button,
input,
select,
textarea {
	color: #303538;
	font-family: Georgia, serif;
	font-size: 20px;
	line-height: 1.6em;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
	clear: both;
    line-height: 1.4;
    margin-top: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

body {
	background: #fff;
}

.container {
    width: 85%;
    max-width: 700px;
    margin: auto;
}

.site-header {
    background: #303538;
    color: #fff;
}

h1 {
font-size: 5rem;
}

h2 {
font-size: 4rem;
}

h3 {
font-size: 3.5rem;    
}

h4 {
font-size: 3rem;    
}

h5 {
font-size: 2.5rem;    
}

h6 {
font-size: 2rem;    
}

.site-title a, .entry-title a {
    text-decoration: none;
}

.site-title {
font-size: 6rem;
line-height: 2em;
margin-top: -20px;
margin-bottom: 0px;
}

.site-description {
    font-size: 3rem;
    line-height: 2em;
    margin-bottom: 40px;
    font-weight: 300;
    color: #ccc;
}

.header-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 100px;
	max-width: 300px;
	height: auto;
    -webkit-border-radius: 50%;
	-moz-border-radius:    50%;
	border-radius:         50%;
}

.site-title a,
.site-title a:visited,
.site-title a:hover,
.site-title a:focus,
.site-title a:active {
    color: #fff;
}

.entry-meta {
    color: #9EABB3;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
}

.entry-meta a,
.entry-meta a:visited,
.entry-meta a:focus,
.entry-meta a:active {
    text-decoration: none;
    color: #9EABB3;
}

.entry-meta a:hover {
    text-decoration: underline;
    color: #9EABB3;
}

.entry-header {
    margin-top: 40px;
}

.entry-summary {
position: relative;
margin-bottom: 6rem;
padding-bottom: 2rem;
border-bottom: #EBF2F6 1px solid;
}

.entry-summary:after {
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border: #E7EEF2 1px solid;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    background: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    box-shadow: #fff 0 0 0 5px;
}

hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

/* Text elements */
p {
	margin-bottom: 1.5em;
}
ul, ol {
	margin: 0 0 1.5em 3em;
}
ul {
	list-style: disc;
}
ol {
	list-style: decimal;
}
li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}
dt {
	font-weight: bold;
}
dd {
	margin: 0 1.5em 1.5em;
}
b, strong {
	font-weight: bold;
}
dfn, cite, em, i {
	font-style: italic;
}
blockquote {
	margin: 0 1.5em;
}
address {
	margin: 0 0 1.5em;
}
pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 15px;
	font-size: 1.5rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}
code, kbd, tt, var {
	font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}
abbr, acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}
mark, ins {
	background: #fff9c0;
	text-decoration: none;
}
sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}
small {
	font-size: 75%;
}
big {
	font-size: 125%;
}
figure {
	margin: 0;
}
table {
	margin: 0 0 1.5em;
	width: 100%;
}
th {
	font-weight: bold;
}
img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}
button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
	*vertical-align: middle; /* Improves appearance and consistency in all browsers */
}
button,
input {
	line-height: normal; /* Addresses FF3/4 setting line-height using !important in the UA stylesheet */
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid #ccc;
	border-color: #ccc #ccc #bbb #ccc;
	border-radius: 3px;
	background: #e6e6e6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, .8);
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
	font-size: 12px;
	font-size: 1.2rem;
	line-height: 1;
	padding: .6em 1em .4em;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
	border-color: #ccc #bbb #aaa #bbb;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02);
}
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
	border-color: #aaa #bbb #bbb #bbb;
	box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
}
input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box; /* Addresses box sizing set to content-box in IE8/9 */
	padding: 0; /* Addresses excess padding in IE8/9 */
}
input[type="search"] {
	-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
	-webkit-box-sizing: content-box; /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */
	-moz-box-sizing:    content-box;
	box-sizing:         content-box;
}
input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
	-webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
	border: 0;
	padding: 0;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	color: #666;
	border: 1px solid #ccc;
	border-radius: 3px;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
	color: #111;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
	padding: 3px;
}
textarea {
	overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
	padding-left: 3px;
	vertical-align: top; /* Improves readability and alignment in all browsers */
	width: 98%;
}

/* Links */
a {
	color: #4a4a4a;
}
a:visited {
	color: #4a4a4a;
}
a:hover,
a:focus,
a:active {
	color: #57A3E8;
}

/* Alignment */
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}
.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto;
}

.text-center {
    text-align: center;
}

/* Text meant only for screen readers */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar */
}

/* Clearing */
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: '';
	display: table;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}


/* =Footer Menu
----------------------------------------------- */

.main-navigation {
    display: inline-block;
}

.main-navigation ul {
	list-style: none;
	margin: 0;
	padding-left: 0;
}
.main-navigation li {
	float: left;
	position: relative;
    margin: 10px;
}
.main-navigation a {
	display: block;
	text-decoration: none;
}
.main-navigation ul ul {
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    background-color: #fff;
	display: none;
	float: left;
	left: 0;
	position: absolute;
	bottom: 1.5em;
	z-index: 99999;
}
.main-navigation ul ul ul {
	left: 90%;
    bottom: 0%;
}
.main-navigation ul ul a {
	width: 200px;
}
.main-navigation ul ul li {
}
.main-navigation li:hover > a {
}
.main-navigation ul ul :hover > a {
    color: #666;
}
.main-navigation ul ul a:hover {
}
.main-navigation ul li:hover > ul {
	display: block;
}
.main-navigation li.current_page_item a,
.main-navigation li.current-menu-item a {
}

/* Header Menu */
  hr#main-nav-divider {
	border: 0; /* Remove default border styles */
	border-top: 0px solid #ccc6; /* Set desired style for the line */
	margin-bottom: 0%;
  }

  nav#main-nav {
	background-color: #303538;
	display: flex; /* Use flexbox for easy centering */
	justify-content: center; /* Center elements horizontally */
	padding: 20px 0;
	font-family: Arial, Helvetica, sans-serif;
  }
  
  .menu-list {
	list-style: none;
	margin: 0;
	padding: 0;
  }
  
  .menu-item {
	display: inline-block; /* Inline-block for spacing */
	margin: 0 15px; /* Add some spacing between list items */
  }
  
  .menu-item a {
	color: #cccccc;
	text-decoration: none;
	font-size: inherit; /* Inherit font size from h2 */
	font-weight: inherit; /* Inherit font weight from h2 */
  }
  
  .menu-item a:visited {
	color: #cccccc; /* Ensure visited links have the same color */
  }
  
  .menu-item:hover a {
	color: #ffffff; /* Change color on hover for all links */
  }
  
  .active a { /* Keep the currently active menu item white */
	color: #ffffff;
  }

  .active a:visited { /* Keep the currently active menu item white */
	color: #ffffff;
  }

  .dropdown {
	position: relative; /* Needed for dropdown positioning */
  }
  
  .dropdown-content {
	display: none; /* Hide dropdown content initially */
	position: absolute; /* Position the dropdown content */
	background-color: #303538;
	min-width: 100px; /* Set a minimum width for the dropdown */
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.7); /* Add a subtle shadow */
	z-index: 1; /* Ensure dropdown is on top */
	text-align: left;
	color: #cccccc;
  }
  
  @media only screen and (max-width: 450px) {
	/* converting drop down menu to align right with it's title to fit on mobile devices */
	.dropdown-content {
		right:0px;
	}
  }

  .dropdown:hover .dropdown-content {
	display: block; /* Show dropdown content on hover */
  }
  
  .dropdown-content li {
	padding: 10px; /* Add padding to dropdown items */
	color: #cccccc;
  }
  
  .dropdown-content a { 
	color: #cccccc;
  }

  .dropdown-content a:visited { 
	color: #cccccc;
  }

  .dropdown-content a:hover { /* Maintain hover effect for dropdown items */
	color: #ffffff;
  }

/* =Content
----------------------------------------------- */

.sticky header {
    border-left: 3px #EBF2F6 solid;
    padding-left: 10px;
}
.hentry {
	margin: 0 0 1.5em;
}
.byline,
.updated {
	display: none;
}
.single .byline,
.group-blog .byline {
	display: inline;
}
.page-content,
.entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}
.page-links {
	clear: both;
	margin: 0 0 1.5em;
}


/* =Asides
----------------------------------------------- */

.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
	display: none;
}


/* =Media
----------------------------------------------- */

.page-content img.wp-smiley,
.entry-content img.wp-smiley,
.comment-content img.wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}
.wp-caption {
	border: 1px solid #ccc;
	margin-bottom: 1.5em;
	max-width: 100%;
}
.wp-caption img[class*="wp-image-"] {
	display: block;
	margin: 1.2% auto 0;
	max-width: 98%;
}
.wp-caption-text {
	text-align: center;
}
.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}
.site-main .gallery {
	margin-bottom: 1.5em;
}
.gallery-caption {
}
.site-main .gallery a img {
	border: none;
	height: auto;
	max-width: 90%;
}
.site-main .gallery dd {
	margin: 0;
}
.site-main .gallery-columns-4 .gallery-item {
}
.site-main .gallery-columns-4 .gallery-item img {
}

/* Make sure embeds and iframes fit their containers */
embed,
iframe,
object {
	max-width: 100%;
}


/* =Navigation
----------------------------------------------- */

.site-main [class*="navigation"] {
	margin: auto;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8rem;
    color: #9EABB3;
    text-align: center;
}
[class*="navigation"] .nav-previous {
    position: relative;
    display: inline-block;
	float: left;
}

[class*="navigation"] .nav-next {
	position: relative;
    display: inline-block;
	float: right;
}

[class*="navigation"] .nav-previous a,
[class*="navigation"] .nav-next a {
    
    padding: 5px 15px;
	border: #EBF2F6 2px solid;
    text-decoration: none;
    border-radius: 30px;
    transition: border ease 0.3s;
}


/* =Comments
----------------------------------------------- */
.comment-list, .comment-list .children {
list-style-type: none;
padding: 0;
}

.comment {
    border-top: #EBF2F6 1px solid;    
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 24px;
}

.comment-content {  
    font-family: Georgia, serif;
}

.comments-title {
    font-weight: 300;
}
.comment-author {
    font-size: 1.6rem;
}

.comment-author img.avatar {
    margin-right: 6px;
}

.comment-metadata {
    font-size: 1.4rem;    
    color: #a2a2a2;
}

.comment-metadata a {
    color: #a2a2a2;
    text-decoration: none;
}

ol.comment-list {
    list-style: none;
    margin: 1.5em 1.5em 0 0;
}

li.comment {
    margin-bottom: 2em;
}

.comment-content {
    margin-left: 1.5em;
}

.comment-content a {
	word-wrap: break-word;
}
.bypostauthor {
}

.comment-body .reply {
    font-size: 1.6rem;
}


/* =Widgets
----------------------------------------------- */

.widget {
	margin: 0 0 1.5em;
}

/* Make sure select elements fit in widgets */
.widget select {
	max-width: 100%;
}

/* Search widget */
.widget_search .search-submit {
	display: none;
}

/* Footer */
.site-footer {
    position: relative;
    margin: 6rem 0 0 0;
    padding: 4rem 0;
    border-top: #EBF2F6 1px solid;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
    line-height: 1.7em;
    color: #BBC7CC;
    text-align: center;
    background: #F7FAFB;
}

.site-footer a {
    color: #BBC7CC; 
    text-decoration: none;
}

.back-to-top {
width: 28px;
height: 28px;
position: absolute;
top: -14px;
left: 50%;
margin-left: -15px;
border: #EBF2F6 1px solid;
text-align: center;
line-height: 2.6rem;
border-radius: 50px;
background: #fff;
transition: box-shadow 0.5s;
text-decoration: none;
}

.widget-area {
    clear: both;
    margin: 8rem 0 0 0;
}

/* =Infinite Scroll
----------------------------------------------- */

/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .paging-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before */
.infinity-end.neverending .site-footer {
	display: block;
}

@media only screen and (max-width: 900px) {
    
    body,
    button,
    input,
    select,
    textarea {
	    font-size: 1.8rem;
    }

    .site-title {
        font-size: 4rem;
        letter-spacing: -1px;
    }

    .site-description {
        font-size: 2rem;
        line-height: 1.5em;
    }

    .entry-meta {
        font-size: 1.5rem;
    }

    h1 {
        font-size: 4.8rem;
        text-indent: -2px;
    }

    h2 {
        font-size: 3.8rem;
    }

    h3 {
        font-size: 3.3rem;
    }

    h4 {
        font-size: 2.8rem;
    }
}

@media only screen and (max-width: 500px) {

    body,
    button,
    input,
    select,
    textarea {
	    font-size: 1.6rem;
    }

    .container {
        width: auto;
        margin-left: 16px;
        margin-right: 16px;
    }

    .site-title {
        font-size: 3rem;
    }

    .site-description {
        font-size: 1.8rem;
    }

    .entry-meta {
        font-size: 1.4rem;
    }

    h1, h2 {
        font-size: 3rem;
        line-height: 1.1em;
        letter-spacing: -1px;
    }

    h3 {
        font-size: 2.8rem;
    }

    h4 {
        font-size: 2.3rem;
    }
}

figcaption { 
	font-size: 10px;
	text-align: center;
}